import {autoinject} from "aurelia-framework";
import {FhirService} from "./FhirService";
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {fhirEnums} from "../classes/fhir-enums";
import {HttpResponseMessage} from "aurelia-http-client";
import {NitTools} from "../classes/NursitTools";
import {UserService} from "./UserService";
import {LocationService} from "./LocationService";
import * as environment from "../../../config/environment.json";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import {PumpService} from "./PumpService";
import {ConfigService} from "./ConfigService";
import {PatientItem} from "../classes/Patient/PatientItem";

const Fhir = require("resources/classes/FhirModules/Fhir");
const moment = require("moment");

@autoinject
export class EncounterService {
    static _fhirService : FhirService;
    get fhirService() : FhirService {
        if (!EncounterService._fhirService)
          EncounterService._fhirService = new FhirService();

        return EncounterService._fhirService;
    }

    constructor(protected i18n: I18N, protected locationService: LocationService) {
    }

    public static StripSessionIdFromUrl(): string {
        const regex = /([\?&])(sessionId=)(.*?)(\?|&|\/|#|$)/gi;
        let r = regex.exec(window.location.href);
        if (r && r != null && r.length >= 3) {
            // get the hash from the sessionId param
            let s = r[3];
            s = decodeURIComponent(s);

            // now we hopefully have the clean hash
            return s;
        }
    }

    /**
     * checks whether a location contains the required ward identifier. Returns TRUE if it needed to be added or if nothing changed FALSE
     * @param loc the location to check
     * @private
     */
    private locationIdentifiersNeedUpdate(loc: any): boolean {
        return LocationService.EnsureLocationIdentifier(loc);
    }

    public async pump(encounterId: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                if (!RuntimeInfo.DataProxy.enabled || !RuntimeInfo.DataProxy.source) {
                    resolve(undefined);
                    return;
                }

                let resources: any[] = [];

                // 1st try to find by id
                let url = `Encounter/${encounterId}`;
                let request = PumpService.GetRequest(url);
                let encounterResponse: HttpResponseMessage = await request.send();
                if (encounterResponse.statusCode != 200) {
                    throw "Error getting Encounter with id: " + encounterId + ".\nStatusCode: " + encounterResponse.statusCode;
                }

                let encounter: any = JSON.parse(encounterResponse.response);
                let userId = UserService.UserName || atob(EncounterService.StripSessionIdFromUrl());
                if (userId.indexOf(':') > -1) userId = userId.split(':')[0]; // get the userId
                /*let extension = Fhir.Tools.SetExtension(encounter, `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/createInfo/createdBy`, userId);
                extension.fhir_comments = 'Created by user per data pumping per config'; */

                if (!encounter.identifier) encounter.identifier = [];
                let existing = encounter.identifier.find(o => o.system?.indexOf('/visitNumber') > -1 && o.value === encounterId);
                if (!existing) {
                    existing = {
                        system: PatientItem.GetVisitNumberSystem(),
                        value: encounterId
                    };

                    encounter.identifier.push(existing);
                }

                let ward: any = undefined;

                encounter.id = encounter.id.replace('_', '-');
                resources.push(encounter);
                let locationBundle: any[] = [];
                if (encounter.location) {  //  when the encounter has locations, check the identifier
                    for (let i = 0; i < encounter.location.length; i++) {
                        if (encounter.location[i].location && encounter.location[i].location.reference) {
                            let url = encounter.location[i].location.reference;
                            let locResponse = await PumpService.GetRequest(url).send();

                            if (locResponse.statusCode === 200) {
                                let loc: any = JSON.parse(locResponse.response);
                                if (loc.physicalType && loc.physicalType.coding && loc.physicalType.coding[0]
                                    && loc.physicalType.coding[0].code && loc.physicalType.coding[0].code === "wa") {
                                    ward = loc;
                                }

                                try {  // ... so let's check whether the location exists at all ..
                                    let localLocation = <any>await this.fhirService.get(url); // .. when this succeeds, the location exists local
                                    //#region successfully got local location from local fhir server, so check the if the identifiers are present
                                    if (!this.locationIdentifiersNeedUpdate(localLocation)) {
                                        if (localLocation && loc) {
                                            if (loc.status !== localLocation.status || loc.name !== localLocation.name) {
                                                localLocation.status = loc.status;
                                                localLocation.name = loc.name;
                                                locationBundle.push(localLocation);
                                                if (ConfigService.Debug)
                                                    console.debug(`Updating location "${loc.name}" with id of "${loc.id}" because the status or name is different`, localLocation);
                                            } else {
                                                if (ConfigService.Debug)
                                                    console.debug(`NOT creating location "${loc.name}" with id of "${loc.id}" because it already exists and they seem ok`, localLocation);
                                            }
                                        }
                                    } else {
                                        if (ConfigService.Debug)
                                            console.debug(`Updating local location "${loc.name}" with id of "${loc.id}" because it has not Identifiers set correctly`, localLocation);
                                        locationBundle.push(localLocation);
                                    }
                                    //#endregion
                                } catch (err) {
                                    // the getting of local Location-resource failed, so we need to create/update a new one
                                    this.locationIdentifiersNeedUpdate(loc); // ensure iddentifiers on new resource
                                    locationBundle.push(loc);
                                    if (ConfigService.Debug)
                                        console.debug(`Creating new location "${loc.name}" with id "${loc.id}"`, loc);
                                }
                            }
                        }
                    }
                }

                if (locationBundle.length > 0) {
                    if (ConfigService.Debug) console.debug('Posting Locations-Bundle:', locationBundle);
                    // create on local-fhir-server
                    await this.fhirService.bundle(locationBundle, HTTPVerb.put, BundleType.batch);
                }

                if (ward && UserService.Practitioner && UserService.Role) {
                    if (!UserService.Role.location) UserService.Role.location = [];
                    if (!UserService.Role.location.find(o => o.reference && o.reference.endsWith('/' + ward.id))) {
                        UserService.Role.location.push({reference: "Location/" + ward.id});
                        resources.push(UserService.Role);
                    }
                }

                if (encounter.subject && encounter.subject.reference) {
                    let url = encounter.subject.reference;
                    let patResponse = await PumpService.GetRequest(url).send();
                    if (patResponse.statusCode === 200) {
                        let pat: any = JSON.parse(patResponse.response);
                        // this.locationService.insertLocation(loc);

                        //#region add SystemHeader/patientNumbers Identifier if not existent, when pumping the patient data
                        // the identifier-value is either gotten from www.kh.de/patientNumber identifier value or we use the patient.id
                        if (!pat.identifier) pat.identifier = [];
                        let identifierValue = pat.id;
                        let khDeIdentifier = pat.identifier.find(o => o.system?.indexOf('/patientNumber') > -1);
                        if (khDeIdentifier && khDeIdentifier.value && khDeIdentifier.value.trim()) {
                            identifierValue = khDeIdentifier.value.trim();
                        }

                        let patientNumbersSystem = PatientItem.GetPatientIdentifierSystem();
                        let patientNumbersIdentifier: any = Fhir.Tools.GetOrCreateExtension(pat, patientNumbersSystem, false);
                        if (!patientNumbersIdentifier) {
                            patientNumbersIdentifier = {
                                system: patientNumbersSystem,
                                value: identifierValue
                            };

                            pat.identifier = [patientNumbersIdentifier, ...pat.identifier];
                        }
                        //#endregion

                        resources.push(pat);
                    }
                }

                // todo: get every questionnaire-response and extract the practitioner from that
                // let qrs =

                for (let i = 0; i < RuntimeInfo.DataProxy.pumpUrls.length; i++) {
                    if (RuntimeInfo.DataProxy.pumpUrls[i]) {
                        let res = await PumpService.PumpUrl(RuntimeInfo.DataProxy.pumpUrls[i], encounterId);
                        res.forEach(r => resources.push(r));
                    }
                }

                if (RuntimeInfo.DataProxy && RuntimeInfo.DataProxy.enabled === true && RuntimeInfo.DataProxy.isPrincipa
                    && window.location.href.indexOf('sessionId') > -1 && RuntimeInfo.Embedded) {
                    sessionStorage.setItem(environment.sessionName, FhirService.EmbeddedUserHash);
                    FhirService.Hash = FhirService.EmbeddedUserHash;
                }

                let items : any = await this.fhirService.bundle(resources, HTTPVerb.put, BundleType.transaction);

                resolve(items);
            } catch (error) {
                let msg = error.message || JSON.stringify(error);
                console.warn(msg);
                reject(msg);
            }
        });
    }
}
