import { UserService } from "./UserService";
import * as environment from "../../../config/environment.json";
import { fhirEnums } from "../classes/fhir-enums";
import { HttpClient, HttpResponseMessage } from "aurelia-http-client";
import { QuestionnaireService } from "./QuestionnaireService";
import { NitTools } from "../classes/NursitTools";
import { RuntimeInfo } from "../classes/RuntimeInfo";
import { ConfigService } from "./ConfigService";
import { PatientItem } from "../classes/Patient/PatientItem";
import { PatientListItem } from "./PatientService";
import { FhirServiceMetaTag } from "./fhirServiceModules/fhir-service-meta-tag";

const Fhir = require("resources/classes/FhirModules/Fhir");
import ResourceType = fhirEnums.ResourceType;
import BundleType = fhirEnums.BundleType;
import HTTPVerb = fhirEnums.HTTPVerb;

const moment = require('moment');

export class FhirService {
    constructor(baseUrl?: string, overrideHash?: string) {
        this.baseUrl = baseUrl;
        this.overrideHash = overrideHash;
        if (!FhirService.Hash && !sessionStorage.getItem(environment.sessionName) && RuntimeInfo.DataProxy
            && RuntimeInfo.DataProxy.enabled === true && RuntimeInfo.DataProxy.isPrincipa === true) {
            FhirService.Hash = FhirService.EmbeddedUserHash;
        }

        if (ConfigService.Tiplu.enabled && ConfigService.Tiplu.token)
            FhirService.Hash = ConfigService.Tiplu.token;
    }

    public baseUrl: string;

    public get tags(): FhirServiceMetaTag {
        return new FhirServiceMetaTag(this.getClient());
    }

    private static _endpoint: string;
    public static SessionName: string;

    private static _tags: FhirServiceMetaTag = undefined;

    public static get Tags(): FhirServiceMetaTag {
        if (!this._tags) this._tags = new FhirServiceMetaTag(new FhirService().getClient(FhirService.Hash));
        return this._tags;
    }
    public static set Tags(value) {
        this._tags = value;
    }

    public static get Endpoint(): string {
        if (!this._endpoint) this._endpoint = sessionStorage.getItem('FhirEndpoint');
        return this._endpoint;
    }

    public static get SmartAuthEndpoint(): string {
        return ConfigService.cfg['fhirSmartAuth'] || '';
    }

    public static set Endpoint(value: string) {
        if (value.indexOf('*') > -1) value = value.replace('*', window.location.hostname);
        this._endpoint = value;
        sessionStorage.setItem('FhirEndpoint', value);
    }

    private static _adminEndpoint: string;

    public static get AdminEndpoint(): string {
        return this._adminEndpoint;
    }

    public static set AdminEndpoint(value: string) {
        if (value.indexOf('*') > -1) value = value.replace('*', window.location.hostname);
        this._adminEndpoint = value;
    }

    private static _fhirVersion : number = undefined;
    public static get FhirVersion() : number {
        if (!this._fhirVersion && sessionStorage.getItem('fhirVersion'))
            this._fhirVersion = parseInt(sessionStorage.getItem('fhirVersion'));
        return FhirService._fhirVersion || FhirService.FallbackFhirVersion;
    }

    public static FallbackFhirVersion : number = undefined;

    public static async EnsureFhirVersion() : Promise<number> {
        if (!FhirService.Endpoint)
            return undefined;

        return new Promise<number>(async (resolve, reject) => {
            try {
                if (this._fhirVersion) return resolve(this._fhirVersion);
                if (this.FallbackFhirVersion) return resolve(this.FallbackFhirVersion);
                
                await this.GetFhirVersion();
                return resolve(this.FhirVersion);
            }
            catch (e) {
                reject(e);
            }
        });
    }

    public static SetFhirVersion(versionString : string) {
        if (versionString.indexOf('.') > -1) {
            versionString = versionString.split('.')[0];
        }

        if (versionString)
            this._fhirVersion = parseInt(versionString);

        sessionStorage.setItem('fhirVersion', String(this._fhirVersion));

        if (ConfigService.Debug)
            console.debug("Fhir-Version set to " + this.FhirVersion);
    }

    public static async GetFhirVersion() {
        if (this.FallbackFhirVersion) return this.FallbackFhirVersion;

        const service = new FhirService();
        const client = service.getClient();
        const responseMessage = await client.options(service.endPoint);
        if (responseMessage.statusCode === 200) {
            const cap : any = JSON.parse(responseMessage.response);
            if (cap?.fhirVersion) {
                this.SetFhirVersion(cap.fhirVersion);
            }
        }
    }

    public static UserNodeId: string;
    public static UserModuleId: string;
    public static EmbeddedUserHash: string;
    public static EncounterAdditionalFilters: string = "class=IMP";

    private static _hash: string;

    public static get Hash(): string {
        if (!this._hash) this.Hash = sessionStorage.getItem(environment.sessionName);
        return this._hash;
    }

    public static set Hash(hash: string) {
        this._hash = hash;
    }

    public static RemoteSetup = {
        fhirUserModuleId: "",
        fhirUserNodeId: "",
        fhirAdmin: "",
        fhirServer: ""
    };

    public overrideHash: string;
    /** Settings for the Offline-Client. Read from Config-File::offlineClient */
    public static OfflineClientSettings = {
        /** Indicates whether the offline client is enabled */
        enabled: false,
        /* how long is a patients record locked when the person who took it offline does not put it back online - in minutes */
        maxLockDuration: 180,
        /** settings regarding the local server */
        local: {
            /** the endPoint-Address of the local fhirServer */
            fhirServer: "http://127.0.0.1:8100",
            /** the http(s)-Address the local CIW installation shall be called from */
            url: "http://localhost:8080?config=local",
            /** settings regarding the local user administration */
            admin: {
                /** the local admin-endpoint-address */
                endpoint: "http://localhost:9000",
                /** the local admin account to use for usercreation. Usually it should be 'root' or 'admin' */
                user: "cm9vdDppZDRhZG1pbg==",
                /** the Smile Node-Id for the local User-Administration, Usually 'Master' */
                userNodeId: "Master",
                /** the Smile Module-Id for the local User-Administration. Usually 'local_security' */
                userModuleId: "local_security"
            }
        },
        /** settings regarding the remote server, iE 207 */
        remote: {
            /** Url where the CIW-Installation can be reached */
            url: "http://localhost:8080?config=debug",
            /** the endPoint-Address of the remote fhirServer */
            fhirServer: "http://207.180.250.172:8100"
        },
        /** indicates whether this is the offline site */
        isOffline: false,
        /** indicates whether a warning message should be displayed when the connection to the fhir-server fails. Only when isOffline=false */
        warn: false,
    };

    public static get IsOffline(): boolean {
        return this.OfflineClientSettings ? this.OfflineClientSettings.isOffline : false;
    }

    public get client(): HttpClient {
        let _client = new HttpClient();
        return this.setupClient(_client, this.baseUrl, this.overrideHash);
    }

    protected static getDeleteResourceArray(resources: any[]): any[] {
        // store encounter and patient to add them at last place in the array of items to delete
        let aEncounter = resources.find(o => o.resourceType === 'Encounter');
        let aPatient = resources.find(o => o.resourceType === 'Patient');

        let toDelete = []; // we will use this to store the correct order for deletions

        // change the order of the resources to delete a bit to ensure successful deletion
        // first exclude the ones we don't want to be deleted
        resources = resources.filter(o => ['Practitioner', 'Organization', 'Questionnaire', 'Location', 'Encounter', 'Patient'].indexOf(o.resourceType) === -1);
        // then add the resources in specific order
        toDelete.push(...resources.filter(o => o.resourceType === 'Procedure'));
        toDelete.push(...resources.filter(o => o.resourceType === 'ProcedureRequest'));
        toDelete.push(...resources.filter(o => o.resourceType === 'Observation'));
        toDelete.push(...resources.filter(o => o.resourceType === 'CarePlan'));
        toDelete.push(...resources.filter(o => o.resourceType === 'RiskAssessment'));
        toDelete.push(...resources.filter(o => o.resourceType === 'Observation'));
        toDelete.push(...resources.filter(o => o.resourceType === 'QuestionnaireResponse'));

        // add the remaining resourceTypes without to the deletion
        let existing = NitTools.Distinct(toDelete.map(o => o.resourceType)); // will contain the resourceTypes that are already existing in delete
        toDelete.push(...resources.filter(o => existing.indexOf(o.resourceType) === -1));

        // lastly add the encounter and patient to delete too
        toDelete.push(aEncounter, aPatient);

        return toDelete.filter(o => o);
    }

    protected static async CopyEncounterEverything(patient: PatientItem, sourceServer: string, targetServer: string) {
        const fsSource = new FhirService();
        fsSource.fetch(NitTools.ExcludeTrailingSlash(sourceServer) + `/Encounter/${patient.encounterId}/$everything?_count=250`)
            .then(async (resources: any[]) => {
                try {
                    resources = resources.filter(o => o.resourceType !== 'Questionnaire');

                    // copy resources to target server
                    if (ConfigService.Debug) console.debug(`Copying ${resources.length} Resources from ${sourceServer} => ${targetServer}`);

                    const fsTarget = new FhirService();
                    const client = fsTarget.getClient(this.Hash, targetServer);
                    let bundle = fsTarget.getBundle(resources, HTTPVerb.put, BundleType.transaction);

                    const responseMessage = await client.post(targetServer, bundle);
                    const responseJS: any = JSON.parse(responseMessage.response);
                    const suspiciousResponses = [];
                    if (responseJS && NitTools.IsArray(responseJS.entry)) {
                        responseJS.entry.forEach((entry) => {
                            if (entry.response && entry.response.status.indexOf('20') != 0) {
                                suspiciousResponses.push(entry.resource.resourceType + '(' + entry.response.status + '): ' + entry.response.location);
                            }
                        });
                    }

                    if (suspiciousResponses.length > 0) {
                        console.warn(`${suspiciousResponses} SuspiciousResponses found:`, suspiciousResponses);
                        throw 'Nicht alle Resourcen konnten erfolgreich kopiert werden!<br />Fall wird nicht kopiert.';
                    } else {
                        /* if (!patient.encounter.identifier) patient.encounter.identifier = [];
                        let existing = patient.encounter.identifier.find(o => o.system && o.system.toUpperCase().endsWith('/OFFLINEPATIENT'));

                        // remove the existing offline identifier. Either this is offline again so it will be updated or it is moved back online then we don't want to have it
                        if (existing) {
                            const index = patient.encounter.identifier.indexOf(existing);
                            if (index > -1) patient.encounter.identifier.splice(index, 1);
                        }

                        // taking patient offline, so mark it as offline on the source server with all needed informations
                        if (ConfigService.Debug) console.debug('Marking patient as offline')

                        patient.encounter.identifier.push({
                            system: NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition) + '/offlinePatient',
                            value: `${UserService.UserName}, ${UserService.UserFirstName} ${UserService.UserLastName}`,
                            period: {
                                start: new Date().toJSON(),
                                end: moment(new Date()).add(4, 'h').toJSON()
                            }
                        }) */
                    }

                    // await fsSource.update(patient.encounter);
                } catch (err) {
                    throw err;
                } finally {
                    RuntimeInfo.IsLoading = false;
                }
            })
            .catch(err => {
                RuntimeInfo.IsLoading = false;
                try {
                    let js = JSON.parse(err);
                    if (js && js.resourceType && js.resourceType === "OperationOutcome" && NitTools.IsArray(js.issue)) {
                        err = js.issue.map(issue => issue.diagnostics).join('<br />');
                    }
                } catch {
                }
                throw err;
            });
    }

    /** Copy the resource to localhost */
    private static async CopyResourceOffline(resourceType: fhirEnums.ResourceType, query?: string): Promise<any> {
        if (resourceType === fhirEnums.ResourceType.codeSystem && !query) {
            query = 'url=http://nursit-institute.com/fhir/StructureDefinition/gmt';
        }

        let csUrlRemote = `${FhirService.Endpoint}/${resourceType}${query ? '?' + query : ''}`;
        let csUrlLocal = `${FhirService.OfflineClientSettings.local.fhirServer}`;
        let fs = new FhirService();

        let remoteBundleItems = await fs.fetch(csUrlRemote);
        let clientLocal = fs.getClient(FhirService.OfflineClientSettings.local.admin.user, FhirService.OfflineClientSettings.local.fhirServer);
        let bundle = fs.getBundle(remoteBundleItems.filter(o => typeof o !== "undefined"), HTTPVerb.put);
        bundle.type = fhirEnums.BundleType.transaction;
        try {
            let responseMessage = await clientLocal.post(csUrlLocal, bundle);

            return JSON.parse(responseMessage.response);
        } catch (e) {
            return e;
        }
    }

    public static async CopyPatientsToLocalServer(patients: PatientItem[] | PatientListItem[], statusChanged: Function): Promise<string[]> {

        let result = [];
        //region startup
        if (typeof statusChanged !== 'function') statusChanged = (newStatus) => {
            if (ConfigService.Debug)
                console.debug(newStatus);
        };
        const bundleItems = [];
        let status = {
            patientId: undefined,
            text: 'Kopieren gestartet',
            reason: 'started'
        };
        //endregion

        //region 1st copy CodeSystems
        status.text = 'Kopiere Code-Systeme';
        statusChanged(status);

        try {
            await this.CopyResourceOffline(fhirEnums.ResourceType.codeSystem);
        } catch (error) {
            status.text = 'Error copying CodeSystems';
            let add = (error.message || error.responseType);
            if (error.statusCode === 401) add = 'Unauthorized';
            if (add) status.text += ` (${add})`;
            status.reason = 'error';
            statusChanged(status);

            return;
        }
        //endregion

        //region 2nd copy the Questionnaires
        status.text = 'Kopiere Questionnaires';
        statusChanged(status);

        try {
            await this.CopyResourceOffline(fhirEnums.ResourceType.questionnaire, 'status=active');
        } catch (error) {
            status.text = 'Error copying Questionnaires';
            let add = error.message || error.responseType;
            if (add) status.text += ` (${add})`;

            status.reason = 'error';
            statusChanged(status);

            return;
        }
        //endregion

        //region 3rd collect Encounter/$everything for each patient
        status.text = 'Sammele Daten';
        status.patientId = undefined;
        status.reason = 'started';
        statusChanged(status);

        const fsSource = new FhirService();
        for (let i = 0; i < patients.length; i++) {
            try {
                status.text = 'Hole Falldaten';
                status.patientId = patients[i].encounterId || patients[i].id;
                statusChanged(status);

                let patientResources = await fsSource.fetch(`${NitTools.ExcludeTrailingSlash(FhirService.Endpoint)}/Encounter/${patients[i].encounterId || patients[i].encounter.id}/$everything?_count=250`);
                patientResources = patientResources.filter(o => o.resourceType !== 'Questionnaire');
                bundleItems.push(...patientResources);
            } catch (error) {
                try {
                    if (error) {
                        let jsError = JSON.parse(error.text || error);

                        if (jsError && jsError.resourceType && jsError.resourceType === 'OperationOutcome') {
                            const oo = <any>jsError;
                            if (typeof error === 'string') error = { message: '' };
                            error.message = '';

                            oo.issue.forEach(issue => {
                                if (issue.diagnostics) {
                                    error.message += issue.diagnostics + '<br />';
                                }
                            });
                        }
                    }
                } catch (e2) {
                    console.warn(e2);
                }

                status.text = error.message || error;
                status.reason = 'error';
                status.patientId = patients[i].id;
                statusChanged(status);

                return;
            }
        }
        //endregion

        //region 4th put the resources to the local server
        status.patientId = undefined;
        status.text = 'Kopiere die Falldaten Offline';
        status.reason = 'info';
        const client = fsSource.getClient(FhirService.Hash, FhirService.OfflineClientSettings.local.fhirServer);
        const bundle = fsSource.getBundle(bundleItems, HTTPVerb.put);
        bundle.type = fhirEnums.BundleType.transaction;
        const resultMessage = await client.post(FhirService.OfflineClientSettings.local.fhirServer, bundle);
        JSON.parse(resultMessage.response);
        //endregion

        // region 5th mark the encounters as offline
        let encounters: any[] = bundleItems.filter(o => o.resourceType === 'Encounter');
        let fs = new FhirService();

        status.patientId = undefined;
        status.reason = 'info';
        status.text = 'Markiere Fälle als kopiert';
        statusChanged(status);
        for (const encounter of encounters) {
            try {
                await fs.tags.update(encounter, {
                    system: NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition) + '/offlinePatient',
                    display: `${UserService.UserName}|${UserService.UserFirstName} ${UserService.UserLastName}`,
                    code: new Date().toJSON()
                });

                result.push(encounter.id);
            } catch (error) {
                console.warn(error);
            }
        }

        fs = undefined;

        // await fsSource.bundle(encounters, HTTPVerb.put);
        //endregion

        status.reason = 'finished';
        status.text = 'Kopieren beendet';
        statusChanged(status);

        return result;
    }

    public static async CopyPatientToOnlineServer(patient: PatientItem, statusChanged?: Function) {
        //region define vars and consts
        const status = {
            text: 'Gestartet',
            totalPages: -1,
            currentPage: -1,
            target: 'local',
            patientId: patient.id,
            reason: 'info'
        };

        // if (!patient.encounter.identifier) patient.encounter.identifier = [];
        // patient.encounter.identifier = patient.encounter.identifier.filter(o => !(String(o.system).toUpperCase().endsWith('/OFFLINEPATIENT')));

        if (typeof statusChanged === "undefined") statusChanged = function (a) {
            console.debug(a);
        };

        status.reason = 'started';
        statusChanged(status);

        status.patientId = patient.id;
        status.reason = 'info';

        const localServer = NitTools.ExcludeTrailingSlash(FhirService.Endpoint);
        const remoteServer = NitTools.ExcludeTrailingSlash(FhirService.OfflineClientSettings.remote.fhirServer);
        const maxFetchPageSize = 200; // ConfigService.Debug ? 10 : 50;
        const fhirService = new FhirService();

        const localEncounterUrl = `${localServer}/Encounter/${patient.encounterId}`;
        const localUrl = localEncounterUrl + '/$everything';
        const localTotalUrl = localUrl + '?_summary=count';
        const localFetchUrl = localUrl + '?_count=' + maxFetchPageSize;
        status.text = 'Zähle lokale Resourcen';
        statusChanged(status);

        const localEncounterTotal = (<any>await fhirService.get(`${localServer}/Encounter?_id=${patient.encounterId}&_summary=count`)).total;
        if (localEncounterTotal === 0) {
            status.reason = 'error';
            status.text = 'Sie haben keine lokale Kopie dieses Patienten.';
            statusChanged(status);
            return;
        }
        //endregion

        //region 1st process and get the local resources
        const localTotalBundle = <any>await fhirService.get(localTotalUrl);
        const localTotal = localTotalBundle.total;
        const localPageCount = Math.round(localTotal / maxFetchPageSize);
        let currentLocalSourcePage = 0;
        status.totalPages = localPageCount;
        status.currentPage = 0;
        status.text = 'Lade lokale Resourcen..';
        statusChanged(status);

        const localResources = await fhirService.fetch(localFetchUrl, true, (e) => {
            currentLocalSourcePage++;
            status.currentPage = currentLocalSourcePage;
            statusChanged(status);
        });

        status.text = 'Verifiziere geladene lokale Resourcen..';
        status.totalPages = -1;
        status.currentPage = -1;
        statusChanged(status);

        if (localResources.length !== localTotal) {
            console.warn('Different size between expected and resulting resource count! Expected: ' + localTotal + ', Resulting: ' + localResources.length);
        } else {
            if (ConfigService.Debug)
                console.debug('Found ' + localResources.length + ' Locally Resources, as expected (total=' + localTotal + ')', localResources);
        }

        status.text = 'Warte...';
        statusChanged(status);
        //endregion

        //region 2nd get the existing remote resources
        const remoteUrl = `${remoteServer}/Encounter/${patient.encounterId}/$everything`;
        const remoteTotalUrl = remoteUrl + '?_summary=count';
        const remoteFetchUrl = remoteUrl + '?_summary=true&_count=' + maxFetchPageSize;

        status.target = 'remote';
        status.text = 'Zähle Server Resourcen..';
        statusChanged(status);

        const remoteTotalBundle = <any>await fhirService.get(remoteTotalUrl);
        const remoteTotal = remoteTotalBundle.total;
        const remotePageCount = Math.round(remoteTotal / maxFetchPageSize);
        let currentRemoteSourcePage = 0;

        status.totalPages = remotePageCount;
        status.text = 'Lade Server Resourcen..';
        const remoteResources = await fhirService.fetch(remoteFetchUrl, true, (e) => {
            currentRemoteSourcePage++;
            status.currentPage = currentRemoteSourcePage;
            statusChanged(status);
        });

        status.totalPages = -1;
        status.currentPage = -1;
        status.target = 'local';
        status.text = 'Warte..';
        statusChanged(status);

        status.target = 'remote';
        status.text = 'Verifiziere Server Resourcen..';
        statusChanged(status);

        if (remoteResources.length !== remoteTotal) {
            status.reason = 'warning';
            status.text = 'Different size between expected and resulting REMOTE resource count! Expected: ' + remoteTotal + ', Resulting: ' + remoteResources.length;
            statusChanged(status);
        } else {
            if (ConfigService.Debug)
                console.debug('Found ' + remoteResources.length + ' Remote Resources, as expected (total=' + remoteTotal + ')', remoteResources);
        }
        //endregion

        //region 3rd ensure that WE got the latest resources in the local database
        let remoteHasBeenUpdated = false;
        localResources.forEach((localResource: any) => {
            const remoteResource = remoteResources.find(o => o.resourceType === localResource.resourceType && o.id === localResource.id);
            if (remoteResource && remoteResource.meta.versionId > localResource.meta.versionId) {
                let hasBeenUpdatedUnexpected = moment(remoteResource.meta.lastUpdated).isAfter(moment(localResource.meta.lastUpdated).add(5, 'minutes'));
                if (hasBeenUpdatedUnexpected) {
                    remoteHasBeenUpdated = true;
                }
            }
        });

        if (remoteHasBeenUpdated) {
            status.text = "Remote Resource sind updated worden. Kein kopieren ohne Datenverlußt möglich!";
            status.reason = 'warning';
            statusChanged(status);
        }
        //endregion

        //region 4th PUT the resources back on the remote server
        status.target = 'remote';
        status.text = 'Synchronisiere Resourcen..';
        statusChanged(status);
        try {
            const pushResources = localResources.filter(o => ['Questionnaire', 'Location', 'Practitioner', 'PractitionerRole', 'CodeSystem', 'Patient'].indexOf(o.resourceType) === -1);
            let client = fhirService.getClient(FhirService.Hash, remoteServer);
            let bundle = fhirService.getBundle(pushResources, HTTPVerb.put);
            const bundleResultMessage = await client.post(remoteServer, bundle); //  fhirService.bundle(remoteResources, );
            try {
                let bundleResult: any = JSON.parse(bundleResultMessage.response);
            } catch (error) {
                console.warn(error.message || error);
            }

            status.text = 'Fertig';
        } catch (error) {
            status.text = JSON.stringify(error);
        }
        statusChanged(status);
        //endregion

        //region 5th delete the local resources
        status.target = 'local';
        status.text = 'Entferne lokale Resourcen...';
        status.totalPages = localResources.length;
        status.currentPage = 1;

        const toDelete = this.getDeleteResourceArray(localResources);
        let deleteClient = fhirService.getClient(FhirService.Hash, localServer);
        for (let x = 0; x < 2; x++) // do it twice to be sure
            await fhirService.bundle(toDelete, HTTPVerb.delete, BundleType.batch, false);
        /* for (let i = 0; i < toDelete.length; i++) {                
            try {                    
                // await deleteClient.delete(`${toDelete[i].resourceType}/${toDelete[i].id}`);
            } catch (error) {
                if (ConfigService.Debug) console.debug(error);
            } // who cares?

            status.currentPage++;
            statusChanged(status);
        } */

        //endregion

        //region finish line
        status.text = 'Fertig';
        status.totalPages = -1;
        status.currentPage = -1;
        statusChanged(status);

        status.target = 'finished';
        statusChanged(status);
        //endregion
    }

    public cleanResource(resource) {
        if (FhirService.FhirVersion != 3)
            return resource;

        if (resource.resourceType === "QuestionnaireResponse") {
            let response = resource;
            const questionnaire = QuestionnaireService.GetQuestionnaireDirect(response?.questionnaire);
            if (!questionnaire)
                return resource;

            Fhir.Questionnaire.FixResponseAnswers(questionnaire, response);
            Fhir.Questionnaire.EnsureStructuredResponse(questionnaire, response);

            // let updatedBy = UserService.Practitioner ? `<a href="Practitioner/${UserService.Practitioner.id}">${UserService.Practitioner.id}</a>` : UserService.UserLastName;
            let result = response; // Fhir.QuestionnaireResponse.RemoveEmptyAnswers(questionnaire, response);
            if (!result.text) {
                result.text = {
                    status: "generated",
                    div: `<div xmlns="http://www.w3.org/1999/xhtml">\n
                            <div>${questionnaire.title || questionnaire.name}</div>\n
                            <div>Created: ${new Date(response.authored).toLocaleString()}</div>\n
                            <div>Updated: ${new Date().toLocaleString()}</div>\n
                        </div>`
                };
            }

            return result;
        } else return resource;
    }

/*********
    public async patch(resource, path: string): Promise<any> {
        try {
            let result = await this.client.patch(FhirService.Endpoint + path, resource);
            return Promise.resolve(JSON.parse(result.response));
        } catch (e) {
            let msg = this.getErrorString(e);
            console.warn(msg);
            return Promise.reject(msg);
        }
    }
**********/

    public async post(resource: any, clean: boolean = true): Promise<any> {
        try {
            await FhirService.waitForService();
            FhirService.working = true;

            if (clean) resource = this.cleanResource(resource);
            let result = await this.client.post(FhirService.Endpoint, resource);
            return Promise.resolve(JSON.parse(result.response));
        } catch (e) {
            if (e && e.requestMessage && e.requestMessage.content)
                e.requestMessage.content = 'POSTED DATA';

            let msg = this.getErrorString(e);

            if (typeof msg === "object" || String(msg).indexOf('[object') > -1) {
                if (e.message)
                    msg = e.message; // || JSON.stringify(e);
            }

            if (e.responseType === "error" || e.statusCode === 0) {
                msg = 'There was a Server-Problem saving the resource to Fhir';
            }

            console.warn(msg, e);

            return Promise.reject(msg);
        }
        finally {
            FhirService.working = false;
        }
    }

    public get hash(): string {
        return Fhir.Rest.Hash;
    }

    public get endPoint(): string {
        return FhirService.Endpoint;
    }

    public getClient(hash?: string, baseUrl?: string): HttpClient {
        let client = new HttpClient();
        return this.setupClient(client, baseUrl); // .configure(x => x.withHeader('Authorization', `Basic ${hash}`));
    }

    public get Rest() {
        return Fhir.Rest;
    }

    public setupClient(client: HttpClient, baseUrl?: string, hash?: string): HttpClient {
        if (!client) client = new HttpClient();

        if (
            (RuntimeInfo.DataProxy && RuntimeInfo.DataProxy.enabled === true && RuntimeInfo.DataProxy.isPrincipa) // datapump config
            && (/sessionId/gi.test(window.location.href) && RuntimeInfo.Embedded)  // only embedded
        ) {
            FhirService.Hash = FhirService.EmbeddedUserHash;
            sessionStorage.setItem(environment.sessionName, FhirService.EmbeddedUserHash);
        } else {
            // check if sessionId for Principa is given in the url:
            if (!FhirService.Hash && /sessionId/gi.test(window.location.href)) {
                let arr = String(window.location.href).match(/[(\?|\&)]([^=]+)\=([^&#/]+)/g);
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i][0] === "&" || arr[i][0] === "?") arr[i] = arr[i].substr(1);

                    if (/sessionId=/i.test(arr[i])) {
                        let val = arr[i].split('=')[1];
                        FhirService.Hash = val;
                        sessionStorage.setItem(environment.sessionName, FhirService.Hash);
                        break;
                    }
                }
            }
        }

        if (!FhirService.Hash && sessionStorage.getItem(environment.sessionName)) {
            FhirService.Hash = sessionStorage.getItem(environment.sessionName);
        }

        client.configure((x) => {
            x.withHeader('Accept', 'application/fhir+json');
            x.withHeader('Content-Type', 'application/fhir+json');
            x.withTimeout(60 * 1000 * 3); // 3 Minutes TimeOut
            if (ConfigService.Debug) {
                x.withInterceptor({
                    request(request) {
                        try {
                            if (ConfigService.DebugFhirRequests) {
                                let message = `FhirClient: Requesting ${request.method} ${request.url}`;
                                if (request.url.indexOf(fhirEnums.ResourceType.questionnaireResponse) > -1 && request.method.toUpperCase() === "PUT" || request.method.toUpperCase() === "POST") {
                                    let r: any = request.content;
                                    if (r.questionnaire) {
                                        let q = QuestionnaireService.GetQuestionnaireDirect(r?.questionnaire);
                                        if (q) {
                                            message = `FhirClient: "${request.method}" QuestionnaireResponse with Questionnaire "${q.name || q.title}" to "${request.url}"`;
                                        }
                                    }
                                }

                                console.debug(message);
                            }
                        } finally {
                            return request;
                        }
                    },
                    response(response) {
                        try {
                            if (ConfigService.Debug && ConfigService.DebugFhirResponses) {
                                console.debug(`FhirClient: Received ${response.statusCode} ${response.requestMessage.baseUrl}`);
                            }

                            if (response.statusCode >= 400) {
                                console.warn("StatusCode >= 400. Response:", response);
                            }
                        } finally {
                            return response;
                        }
                    }
                });
            }

            x.withHeader("Prefer", "return=representation");
            if (ConfigService.UseOAuth2) {
                ConfigService.EnsureTokens();
                x.withHeader('Authorization', `Bearer ${ConfigService.AccessToken}`);
            } else {
                if (ConfigService.Tiplu.enabled && ConfigService.Tiplu.token) {
                    x.withHeader('Authorization', `Basic ${ConfigService.Tiplu.token}`);
                } else {
                    if (!hash) hash = FhirService.Hash;
                    if (hash) {
                        if (UserService.Login.usePrincipa && !sessionStorage.getItem(environment.sessionName + '_impersonation')) {
                            x.withHeader('Authorization', hash);
                        } else {
                            x.withHeader('Authorization', `Basic ${hash}`);
                        }
                    }
                }
            }

            /* if (!baseUrl && FhirService.IsOffline && FhirService.OfflineClientSettings.enabled && FhirService.OfflineClientSettings.fhirServer) {
                baseUrl = FhirService.OfflineClientSettings.fhirServer;
                if (ConfigService.Debug) console.debug('Redirecting FhirBase to ' + baseUrl + ' because of OFFLINE-Mode');
            } */

            x.withBaseUrl(baseUrl || FhirService.Endpoint);
        });

        return client;
    }

    public async tryLogin(hash, fhirServer?: string): Promise<boolean> {
        let result;
        try {
            FhirService.Hash = undefined;
            let [u,p] = atob(hash).split(':');
            u = String(u).toUpperCase();
            result = await this.client.createRequest(`${NitTools.ExcludeTrailingSlash(fhirServer || FhirService.Endpoint)}/Practitioner?identifier=${u}&active=true&_count=1`)
                .withHeader('Authorization', `Basic ${hash}`)
                .asGet()
                .send();

            FhirService.Hash = hash;
            return true;
        } catch (error) {
            if (!ConfigService.IsTest)
                console.warn(error);
            else {
                console.warn("Login-Error:", result.status);
            }

            return false;
        }
    }

    private async _fetchBundles(uri: string, onPage?: Function, fetchAllPages: boolean = true): Promise<any[]> {
        let result = [];
        if (!uri) return result;

        const client = this.setupClient(undefined, this.baseUrl, this.overrideHash);
        let responseMessage = await client.get(uri);
        let b: any = JSON.parse(responseMessage.response);
        result.push(b);
        if (typeof onPage === "function") {
            onPage(b, b.entry ? b.entry.length : 0);
        }

        let nextLink = b.link ? b.link.find(o => o.relation === "next") : undefined;
        if (fetchAllPages && nextLink) {
            let b2 = await this._fetchBundles(nextLink.url, onPage);

            b2.forEach(nb => {
                result.push(nb);
            });

            return Promise.resolve(result);
        } else {
            return Promise.resolve(result);
        }
    }

    public parseSmileResponse(response): string {
        return FhirService.ParseSmileResponse(response);
    }

    public static ParseSmileResponse(response): string {
        try {
            if (response.response) response = JSON.parse(response.response);
            let msg = "";
            if (response.issue) {
                let issues: any[] = response.issue;
                issues.forEach(issue => {
                    msg += `${issue.severity}: ${issue.diagnostics}\n`;
                });
            }

            return msg.trim();
        } catch (e) {
            return String(response);
        }

    }

    public getErrorString(e): string {
        return e.message || this.parseSmileResponse(e) || e.response || JSON.stringify(e);
    }

    public async count(type: string, searchParams?: string[]): Promise<number> {
        try {
            let param = "_summary=count";
            if (searchParams && searchParams.length > 0) {
                // dont use _sort or given _summary or _count on count
                param = searchParams.filter(o => o && o.indexOf('_sort') === -1 && o.indexOf('_summary') === -1 && o.indexOf('_count') === -1).join('&');
                if (param.indexOf('_summary=') === -1) {
                    param += '&_summary=count';
                }
            }

            let result: HttpResponseMessage = await this.client.get(`${type}?${param}`);
            let js = JSON.parse(result.content);

            return parseInt(js.total);
        } catch (error) {
            console.warn(error);
            return 0;
        }
    }

    /**
     * fetches all pages of a resource from the Fhir-Endpoint and returns them as one bundle or array
     * @param uri the URI to fetch the resources from
     * @param flatData indicates whether the returned resources should be returned as an array (true) or as bundle-resource (false)
     * @param onPage event to execute when a page is loading
     */
    fetch(uri: string, flatData: boolean = true, onPage?: Function, fetchAllPages: boolean = true): Promise<any> {
        return new Promise<any[] | any>(async (resolve, reject) => {
            try {
                let resType = uri.indexOf('?') > -1 ? uri.split('?')[0] : uri;
                resType = resType.indexOf('/') > -1 ? resType.split('/')[0] : resType;
                resType = '[FhirService] Download Resource of type: "' + resType + '"';

                if (uri.indexOf('?') > -1) {
                    let backup = uri;
                    let sa = uri.split('?');
                    let main = sa[0];
                    let search = sa[1];
                    if (search.indexOf('&') === -1 && search.indexOf('=') > -1) {
                        let kvp = search.split('=');
                        kvp[1] = encodeURIComponent(kvp[1]);
                        search = `${kvp[0]}=${kvp[1]}`;
                    } else {
                        if (search.indexOf('&') > -1) {
                            let searchParams = search.split('&');
                            for (let sp = 0; sp < searchParams.length; sp++) {
                                let kvp = searchParams[sp].split('=');
                                kvp[1] = encodeURIComponent(kvp[1]);
                                searchParams[sp] = `${kvp[0]}=${kvp[1]}`;
                            }

                            search = searchParams.join('&');
                        }
                    }

                    uri = main + "?" + search;
                }

                let resultBundles = await this._fetchBundles(uri, onPage, fetchAllPages);

                // if the result should be returned as a bundle and not flat data, iE when there is a _summary=count
                if (!flatData) {
                    // gather all fetches bundle-pages into one bundle and return that new bundle
                    let result: any = {
                        resourceType: fhirEnums.ResourceType.bundle,
                        type: fhirEnums.BundleType.searchset,
                        entry: [],
                        id: NitTools.Uid(),
                        link: [
                            { relation: "self", url: uri }
                        ]
                    };

                    for (const b of resultBundles.filter(o=>o.entry)) {
                        for (const entry of b.entry.filter(e => e.resource)) {
                            result.entry.push(entry);
                        }
                    }

                    result.total = result.entry.length;
                    resolve(result);
                } else {
                    let resultArray: any[] = [];
                    for (const bundle of resultBundles) {
                        if (!bundle.entry) continue;
                        for (const entry of bundle.entry) {
                            if (!entry.resource) continue;
                            resultArray.push(entry.resource);
                        }
                    }

                    resolve(resultArray);
                }
            } catch (e) {
                reject(e.message || e.response || JSON.stringify(e));
            }
        }
        );
    }

    private static working : boolean = false;
    private static waitForService(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            if (!this.working) {
                resolve();
            } else {
                window.setTimeout(async () => {
                    await this.waitForService();
                    resolve();
                }, 50);
            }
        });
    }

    public update(resource, clean: boolean = false): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                await FhirService.waitForService();
                FhirService.working = true;
                // remove empty extension arrays:
                if (resource && NitTools.IsArray(resource["extension"]))
                    if ((<any[]>resource["extension"]).length === 0)
                        delete resource["extension"];
                let result = await this.client
                    .createRequest(`${String(resource.resourceType)}/${resource.id}`)
                    .withContent(clean ? this.cleanResource(resource) : resource)
                    .asPut()
                    .send();                
                    let js = result.response ? JSON.parse(result.response) : '';

                    resolve(js);
            } catch (e) {
                if (ConfigService.Debug)
                    debugger;
                
                reject(this.getErrorString(e));
            }
            finally {
                FhirService.working = false;
            }
        });
    }

    public delete(resource: any) {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                await FhirService.waitForService();
                FhirService.working = true;
                let result = await this.client.createRequest(String(resource.resourceType) + "/" + resource.id).asDelete().send();
                let js = JSON.parse(result.response);
                if (!result.isSuccess) throw (JSON.stringify(js));

                resolve(true);
            } catch (e) {
                reject(this.getErrorString(e));
            }
            finally {
                FhirService.working = false;
            }
        });
    }

    public deleteUrl(url: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                await FhirService.waitForService();
                FhirService.working = true;
                let result = await this.client.createRequest(url).asDelete().send();
                let js = JSON.parse(result.response);
                if (!result.isSuccess) throw (JSON.stringify(js));

                resolve(true);
            } catch (e) {
                reject(this.getErrorString(e));
            }
            finally {
                FhirService.working = false;
            }
        });
    }

    public get(url: string, returnRawException : boolean = false): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let result = await this.client.createRequest(url).asGet().send();
                resolve(<any>JSON.parse(result.response));
            } catch (e) {

                reject(returnRawException ? e : this.getErrorString(e));
            }
        });
    }

    public create(resource: any, postToBase: boolean = false): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                await FhirService.waitForService();
                FhirService.working = true;
                if (!resource) {
                    reject("No resource given to create");
                    return;
                }

                try {
                    let resultMessage: HttpResponseMessage = await this.client.post(postToBase ? '/' : resource.resourceType, this.cleanResource(resource));
                    let resultObject = JSON.parse(resultMessage.response);

                    resolve(resultObject);
                } catch (error) {
                    let msg = this.getErrorString(error);
                    console.warn(msg);
                    reject(msg);
                }
            }
            finally {
                FhirService.working = false;
            }
        });
    }

    public getBundle(resources: any[], method: HTTPVerb | HTTPVerb[], bundleType: BundleType = BundleType.batch): any {
        let bundle: any = {
            entry: [],
            type: bundleType,
            resourceType: ResourceType.bundle,
            id: NitTools.Uid()
        };

        resources.forEach((resource: any, idx) => {
            if (typeof resource === "undefined") {
                return;
            }

            const entryMethod = Array.isArray(method) ? method[idx] : method;
            let url, entry;

            if (resource._url) {
                url = resource._url;
            } else {
                url = resource.resourceType;
                if (entryMethod !== HTTPVerb.post) {
                    url += `/${resource.id}`;
                }
            }

            if (entryMethod === HTTPVerb.delete) {
                entry = {
                    request: {
                        method: entryMethod,
                        url: url
                    }
                };
            } else {
                entry = {
                    resource: resource,
                    request: {
                        method: entryMethod,
                        url,
                    }
                };
            }

            if (entry.request.method === "DELETE") {
                delete entry.resource;
                bundle.entry.push(entry);
            } else {
                if (resource._url || !bundle.entry.find(o => o.resource && o.resource.id === entry.resource.id)) {
                    entry.fullUrl = `${entry.resource.resourceType}/${entry.resource.id}`;
                    bundle.entry.push(entry);
                }
            }

            delete resource._url;

            if (entryMethod === HTTPVerb.post && bundleType === BundleType.transaction) {
                if (entry.resource && entry.resource.id && entry.resource.id.indexOf('urn:uuid:') === 0) {
                    entry.fullUrl = entry.resource.id;
                    delete entry.resource.id;
                }
            }
        });

        return bundle;
    }

    public async sendBundle(bundle: any, clean: boolean = true): Promise<any> {
        try {
            await FhirService.waitForService();
            FhirService.working = true;
            if (bundle && bundle.entry && clean) {
                bundle.entry.filter(o => o.resource).forEach(bundleEntry => {
                    bundleEntry.resource = this.cleanResource(bundleEntry.resource);
                });
            }

            let result: HttpResponseMessage = await this.client.post(this.baseUrl, bundle);

            let resultObject: any = JSON.parse(result.response);
            let s = "";
            if (resultObject.entry) {
                resultObject.entry.forEach(entry => {
                    if (entry.response) {
                        let response = entry.response;
                        if (response && response.status && (response.status.indexOf("4") === 0 || response.status.indexOf("5") === 0)) {
                            s = response.status;
                            if (response.outcome && response.outcome["issue"]) {
                                response.outcome["issue"].forEach(issue => {
                                    s += "\n" + issue.code;
                                    s += "|" + issue.severity;
                                    s += ": " + issue.diagnostics + "\n";
                                });
                            }
                        }

                        s = s.trim();
                    }
                });
            }

            if (s) {
                console.warn(s);
                throw (s);
            }

            return resultObject;

        } catch (error) {
            throw this.getErrorString(error);
        }
        finally {
            FhirService.working = false;
        }
    }

    /***
     * Uploads a bundle, built from the given resources[] to the Server
     * @param resources the resources to send as a bundle
     * @param verb how the resources in the bundle should be treatet
     * @param bundleType the type of the bundle to send to the server. Default: transaction
     * @param clean perform a cleanup of the resources before sending them to the server
     */

    public async bundle(resources: any[], verb: fhirEnums.HTTPVerb | fhirEnums.HTTPVerb[], bundleType: BundleType = BundleType.transaction, clean: boolean = true): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            await FhirService.waitForService();

            try {
                let bundle = this.getBundle(resources, verb, bundleType);

                if (!bundle.entry || bundle.entry.length === 0) {
                    resolve(bundle);
                    return;
                }

                this.sendBundle(bundle, clean)
                    .catch(e => {
                        reject(e);
                    })
                    .then(result => {
                        resolve(<any>result);
                    });
            }
            catch (e) {
                reject(e);
            }
            finally {

            }
        });
    }


    bundleBuilder(type = BundleType.batch) {
        const that = this;

        function bundleBuilder(bundleType) {
            this.bundleType = bundleType;
            this.bundleResources = [];
            this.bundleMethods = [];
            this.callbacks = {};
        }

        bundleBuilder.prototype.add = function (resource, method, callback = null) {
            this.bundleResources.push(resource);
            this.bundleMethods.push(method);

            if (callback) {
                this.callbacks[this.bundleResources.length - 1] = callback;
            }
        };

        bundleBuilder.prototype.exec = async function () {
            let bundleResponse;

            try {
                bundleResponse = await that.bundle(this.bundleResources, this.bundleMethods, this.bundleType);
            } catch (e) {
                console.warn(e);
            }

            if (bundleResponse) {
                bundleResponse.entry.forEach((entry, idx) => {
                    const resource = entry.resource;

                    if (resource && this.callbacks[idx]) {
                        this.callbacks[idx](resource);
                    }
                });
            }
        };

        bundleBuilder.prototype.reset = function () {
            this.bundleResources = [];
            this.bundleMethods = [];
            this.callbacks = {};
        };

        return new bundleBuilder(type);
    }
}
